(function($){
  $(document).ready(function() {
    var scrollTop = $(".top-btn");
  
    $(window).scroll(function() {
      var topPos = $(this).scrollTop();
      if (topPos > 100) {
        $(scrollTop).css("opacity", "1");
  
      } else {
        $(scrollTop).css("opacity", "0");
      }
  
    }); 
    $(scrollTop).click(function() {
      $('html, body').animate({
        scrollTop: 0
      }, 800);
      return false;
  
    });

    (function(){
      var containerMargin = $('.container').css('margin-left');
      var sliderContent = $('.slider__item-content');
      $(sliderContent).css('margin-left', containerMargin);

      $(window).resize(function(){
        var containerMargin = $('.container').css('margin-left');
        var sliderContent = $('.slider__item-content');
        $(sliderContent).css('margin-left', containerMargin);
      });
    })();
    

    (function(){
      $('.slider-container').slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        centerMode: true,
        variableWidth: true
      });
      var imgHeight = $('.slider-container .slider__item-img:first-child img').height();
      var img = $('.slider-container .slider__item-img img');
      var imgContainer = $('.slider-container .slider__item-img');
      var arrowRight = $('.slider__arrow-right');
      $(arrowRight).on('click', function(){
        $('.slider-container').slick('slickNext');
      });
      $(img).css('height', imgHeight);
      $(imgContainer).css('height', imgHeight);
    })();

    (function () {
      if ($('.nav__scrollable .nav-link.active').length) {
        var position = $('.nav__scrollable .nav-link.active').position(),
          positionLeft = position.left;
        $('.main__tabs-right_scroll-container').scrollLeft(positionLeft);
      }
    })();

    //Phone menu
    (function(){
      $('.phone__header-btn').on('click', function(){
        $('.phone__header-menu').css({
          'min-height': '100vh',
          'border': ' 25px solid #fff2f2'
        });
      });
      $('.phone__header-close').on('click', function(){
        $('.phone__header-menu').css({
          'min-height': '0',
          'border': ' 0 solid #fff2f2'
        });
      });
    })();

    (function(){
      var containerMargin = $('.container').css('margin-left');
      containerMargi = +containerMargin + +10;
      $('.services__how-tabs-nav_left-bg').css({
        'width': containerMargin
      });
      $(window).resize(function(){
        var containerMargin = $('.container').css('margin-left');
        containerMargi = +containerMargin + +10;
        $('.services__how-tabs-nav_left-bg').css({
          'width': containerMargin
        });
      })
    })();

    (function(){
      $(".carousel").swipe({

        swipe: function(event, direction, distance, duration, fingerCount, fingerData) {
      
          if (direction == 'left') $(this).carousel('next');
          if (direction == 'right') $(this).carousel('prev');
      
        },
        allowPageScroll:"vertical"
      
      });
    })();

  });
})(jQuery);